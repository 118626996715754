import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import myConfiguredSanityClient from "../../sanityClient"
import imageUrlBuilder from "@sanity/image-url"
import ContactBox from "../components/contactBox"

// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, animateScroll as scroll } from "react-scroll"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"

const Membership = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityAnnualMeeting {
        title
        _rawDescription
        button
        calendarInfo {
          date(formatString: "DD. MMMM YYYY | HH:mm", locale: "da_DK")
        }
        Info {
          name
          description
          image {
            hotspot {
              y
              x
            }
            asset {
              url
            }
          }
        }
      }
    }
  `)

  function getFocalPointsX(data) {
    let xValue = 0.5

    if (data.image.hotspot !== null) {
      xValue = data.image.hotspot.x
    }

    return xValue
  }

  function getFocalPointsY(data) {
    let yValue = 0.5

    if (data.image.hotspot !== null) {
      yValue = data.image.hotspot.y
    }

    return yValue
  }

  const builder = imageUrlBuilder(myConfiguredSanityClient)

  function urlFor(source) {
    return builder.image(source)
  }

  function getDescription(data) {
    return data.sanityAnnualMeeting._rawDescription[0].children[0].text
  }

  return (
    <Layout>
      <SEO title="Årsmøde" description={getDescription(data)} />

      <section className="membership">
        <div className="activitySection">
          <div className="firstActivity">
            <div className="activityInfo">
              <p className="linkTemp dateFade">
                {getRightTime(data.sanityAnnualMeeting.calendarInfo.date)}
              </p>
              <h1 className="titleFadeIn">{data.sanityAnnualMeeting.title}</h1>

              <div className="descriptionFadeIn">
                <BlockContent
                  blocks={data.sanityAnnualMeeting._rawDescription}
                  projectId="q2jj4zmh"
                  dataset="production"
                  serializers={serializers}
                />
              </div>

              <div className="whiteSpace"></div>

              <Link
                className="headerContactLink buttonTemp buttonFadeIn"
                activeClass="active"
                to="infoSection"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                style={{ cursor: "pointer" }}
              >
                {data.sanityAnnualMeeting.button}
              </Link>
              <div className="whiteSpace"></div>
            </div>

            <div className="contactWrapper fadeUp">
              <p className="signUp">Tilmeld</p>
              <p className="contactDate">
                {getRightTime(data.sanityAnnualMeeting.calendarInfo.date)}
              </p>
              {/* <p className="contactDate">
              {data.sanityAnnualMeeting.calendarInfo.date}
            </p> */}
              <ContactBox
                data={data.sanityAnnualMeeting.title}
                name={"aarsmoede-form"}
              />
            </div>
          </div>
        </div>

        <div className="infoSection" id="info">
          <div className="infoGrid">
            {data.sanityAnnualMeeting.Info.map(function (info) {
              const image = info?.image?.asset?.url
              return (
                <div className="infoItem">
                  <div className="gatsby-image-container">
                    {image && (
                      <img
                        className="image"
                        src={urlFor(image)
                          .focalPoint(
                            getFocalPointsX(info),
                            getFocalPointsY(info)
                          )
                          .width(350)
                          .height(550)
                          .fit("crop")
                          .auto("format")
                          .url(image)}
                        alt={info.title}
                      />
                    )}
                  </div>
                  <div className="textContent">
                    <h2>{info.name}</h2>
                    <p>{info.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Membership

function getRightTime(date) {
  console.log(date)
  let firstHalf = date.split("|")[0]
  let secondHalf = date.split("|")[1]
  let secondHalfDivided = secondHalf.split(":")[0]
  let newHour = parseInt(secondHalfDivided) + 2
  let secondHalfDividedEnding = secondHalf.split(":")[1]
  return firstHalf + " | " + newHour + ":" + secondHalfDividedEnding
}
